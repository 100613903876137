/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
// import { useStaticQuery, graphql } from 'gatsby'

import Container from "react-bootstrap/Container"

import HeaderImage from './header-image'
import SiteNavbar from "./nav";
import Footer from "./footer";

const Layout = ({ children }) => {
    // const data = useStaticQuery(graphql`
    //   query SiteTitleQuery {
    //     site {
    //       siteMetadata {
    //         title
    //       }
    //     }
    //   }`)

    return (
      <>
        <SiteNavbar />
        <HeaderImage />
        <Container>
            <main>{children}</main>
        </Container>
        <Footer />
      </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
