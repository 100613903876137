import React from "react"
import PropTypes from "prop-types";

const HeaderImage = ({ imageUrl }) => {
  const backImage = { backgroundImage: 'url("' + imageUrl + '")'
  }


  return (
    <header className="masthead" style={ backImage } >
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto">
            <div className="post-heading">
              <h1>Man must explore, and this is exploration at its greatest</h1>
              <h2 className="subheading">Problems look mighty small from 150 miles up</h2>
              <span className="meta">Posted by Scouts on June 14, 2020</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

HeaderImage.propTypes = {
  imageUrl: PropTypes.string,
}

HeaderImage.defaultProps = {
  imageUrl: `https://assets.lbdscouts.org.uk/wp-content/uploads/2017/08/03223048/IMG_3423.jpg`,
}


export default HeaderImage